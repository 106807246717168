.upload-area {
    margin-bottom: 40px;
}

#upload-zone {
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    padding: 50px;
    text-align: center;
    cursor: pointer;
    transition: border-color .3s;
    transition-property: border-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    &:hover {
        border-color: #40a9ff;
    }
}