.detail-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 85vh;
}
.detail-search-box {
  max-width: 50rem;
  margin-bottom: 1rem;
}
.search-buttons {
  margin-bottom: 1rem;
}
.search-buttons > * {
  margin: 0.25rem;
}
.ais-Highlight-highlighted {
  background-color: #FFFF00
}
.ais-Snippet-highlighted {
  background-color: #FFFF00
}