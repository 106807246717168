@import '~antd/dist/antd.less';

.profile-pic {
		left: -19.57%;
		right: -15.22%;
		top: 0%;
		bottom: -2.17%;
		padding-left: 10px;
}

.site-layout-background {
	background: #fff;
}

.site-layout-sidebar {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    min-height: 1000px;
}

